import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Seo from '../components/seo';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;
  // FIX const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;
  const image = getImage(post.frontmatter.featuredImage);

  return (
    <>
      <Seo title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />
      <div class="container bg-cg1 dark:bg-cg8d text-ct2 dark:text-ct1d ">
        <article itemScope itemType="http://schema.org/Article">
          <header className="mx-auto text-center ">
            <h1 className="text-c1 dark:text-c1d" itemProp="headline">
              {post.frontmatter.title}
            </h1>
            <p className="mt-2 text-sm font-light ">{post.frontmatter.date}</p>
          </header>
          <GatsbyImage
            className="mt-4 dark:grayscale shadow-lg w-full aspect-[2/1] border"
            image={image}
            alt={post.frontmatter.title}
          />
          <section
            className="prose prose-slate text-ct2 dark:text-ct1d dark:prose-invert mx-auto mt-4"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <hr className="mt-4" />
        </article>
        <nav className="py-4">
          <ul className="max-w-prose mx-auto flex flex-wrap justify-between font-sans text-c2 dark:text-c1d font-bold">
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
